/* Typography */
@font-face {
  font-family: 'Montserrat';
  src: url('./fonts/Montserrat-VariableFont_wght.ttf');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'GreatVibes';
  src: url('./fonts/GreatVibes-Regular.ttf');
  font-weight: normal;
  font-style: normal;
}
